import { ArrowBackIosNew, CloseOutlined, FavoriteBorderOutlined, GridView, Reorder } from "@mui/icons-material"
import { Box, Divider, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { hideCatalog, setCloseCompare, setResultCompare, showCatalog, toggleBookmarks } from "../../../../../redux/general/generalActions"
import { useContext, useEffect, useState } from "react"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { useNavigate, useSearchParams } from "react-router-dom"
import changeSpaceIcon from "../../../../../assets/visualizer/change_space.png"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { useTranslation } from "react-i18next"
import { IFrameContext } from "../../../../../providers/IFrameContext"
import { setDisplayedProducts } from "../../../../../redux/product/productActions"
import { EventsService } from "../../../../../common/services/events.service"
import { ChangeSpaceClickedEvent, Event, EventTypes, ProductListLayoutChangedEvent } from "../../../../../common/models/events/event.model"
import { SettingsService } from "../../../../../common/services/settings.service"
import { VendorThemeContext } from "../../../../../providers/VendorThemeProvider"


export const WebPoppingBar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {vendorAlias} = useContext(VendorThemeContext);

    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)
    const {products} = useSelector((state: FullState) => state.productsCatalog)
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const {isOnBookmarks, isCatalogVisible, companyLogo} = useSelector((state: FullState) => state.generals)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const {visualizeData} = useSelector((state: FullState) => state.surfaces)
    const isOnIframe = useContext(IFrameContext);

    const showBookmarks = (state) => {
        if(!isOnBookmarks && state === true)
          EventsService.push(new Event(EventTypes.ProductListLayoutChangedEvent, new ProductListLayoutChangedEvent("bookmarks")))
        else if(isOnBookmarks && state === false)
          EventsService.push(new Event(EventTypes.ProductListLayoutChangedEvent, new ProductListLayoutChangedEvent("default")))
        dispatch(toggleBookmarks(state))
        if(state)
          spaceCatalogService.showBookmarksOnly(bookmarks)
        else {
          dispatch(setDisplayedProducts(products))
        }
      }

    useEffect(() => {
        if(bookmarks.length>0 && isOnBookmarks)
          spaceCatalogService.showBookmarksOnly(bookmarks)
        
        if(bookmarks.length === 0 && isOnBookmarks){
          dispatch(setDisplayedProducts(products))
          dispatch(toggleBookmarks(false))
        }
    },[bookmarks])

    const handleChangeSpace = () => {
      EventsService.push(new Event(EventTypes.ChangeSpaceClickedEvent, new ChangeSpaceClickedEvent(selectedSpace, visualizeData)))

      dispatch(setCloseCompare(true))
      dispatch(setResultCompare(false))
      if(vendorAlias)
        navigate(`/visualizer/${vendorAlias}`)
      else 
        navigate(`/visualizer`)
    }

    const handleCloseIframe = () => {
      window.parent.postMessage(
        {
          type: "FRAME_CLOSED"
        },
        "*"
      );
    }

    return (
      <Box display="flex" flexDirection={"row"}>
            {isOnIframe && <CloseOutlined fontSize='medium' onClick={handleCloseIframe}
              sx={{ cursor: 'pointer', backgroundColor: "white", padding: 1, boxShadow: '1px 0 5px -1px #88888850',
              color: 'black', 
              marginRight: 2,
              borderRadius: 2 }} /> }
            
            {companyLogo && <img src={companyLogo} style={{maxWidth: "10vw", maxHeight:"40px", objectFit: "contain"}}/>}

            <Box display="flex" flex={2} justifyContent={"flex-end"} >

              <Box alignItems={"center"}  display="flex" marginRight={1} flexDirection={"row"}
                onClick={() => handleChangeSpace()} 
                                sx={{ cursor: 'pointer', backgroundColor: "white", padding: 1, boxShadow: '1px 0 5px -1px #88888850',
                                color: 'black', 
                                borderRadius: 2 }} >
                {/* <img src={changeSpaceIcon} height={20} /> */}
                <i className="fad fa-image fa-lg" style={getFaIconsStyles()} ></i>

                <Typography variant="caption" fontWeight={"bold"} marginLeft={1} >{t('VISUALIZER.BUTTONS.CHANGE_SPACE')}</Typography>
              </Box>

              {isCatalogVisible && <Box >
                  {/* <GridView 
                        fontSize='small' 
                        sx={{ cursor: "pointer", 
                        backgroundColor: !isOnBookmarks ? 'primary.main': '#E8EDF5', color: isOnBookmarks ? "gray": 'white', marginRight: 1,
                        borderRadius: 2, padding: 1 }} onClick={() => showBookmarks(false)}/> */}

                {bookmarks.length > 0 && <Box marginInlineStart={0.5}
                    onClick={() => showBookmarks(!isOnBookmarks)}
                    sx={{ cursor: "pointer", 
                      backgroundColor: isOnBookmarks ? 'white': '#E8EDF5', color: !isOnBookmarks ? "gray": 'white', boxShadow: isOnBookmarks ? '1px 0 5px -1px #88888850':"none",
                      borderRadius: 2, padding: 1 }}>
                  <i className="fad fa-heart fa-xl" style={{...getFaIconsStyles(), marginLeft: 0,
                    //@ts-ignore
                    "--fa-secondary-color": "#FA5961"}}
                  ></i>
                </Box>}

                  {/* <FavoriteBorderOutlined fontSize='small'
                        sx={{ cursor: "pointer",
                        backgroundColor: isOnBookmarks ? 'primary.main': '#E8EDF5', color: !isOnBookmarks ? "gray": 'white', 
                          borderRadius: 2, padding: 1 }} onClick={() => showBookmarks(true)}/> */}
              </Box>}
            </Box>
          </Box>
    )
}