import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getFaIconsStyles } from "../../../../../common/styles/styles";
import { useTranslation } from "react-i18next";

export const ScrollToTop = ({ containerRef }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const onScroll = () => {
            if (containerRef.current.scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
            if(containerRef.current.scrollBottom === 0){
                alert("bottom")
            }
        };
        containerRef.current?.addEventListener('scroll', onScroll);
        return () => {
            containerRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const scrollToTop = () => {
        containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return <Button
        fullWidth
        onClick={scrollToTop} variant="text" color="primary"
        sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            "&:hover": {
                backgroundColor: "white",
            },
            textTransform: "none",
            transition: "opacity 0.5s",
            opacity: isScrolled ? 1 : 0,
            pointerEvents: isScrolled ? "auto" : "none",
        }}>
        {t('VISUALIZER.BUTTONS.SCROLL_TO_TOP')}
        <i className="fa-duotone fa-arrow-up" style={{ ...getFaIconsStyles(), marginLeft: "5px" }}></i>
    </Button>
}