import { ProductModel } from "../models/product.model";
import * as _ from 'lodash'
import { setDisplayedProducts, setProducts, setSelectedProductResult } from "../../../redux/product/productActions";
import { useDispatch, useSelector } from "react-redux";
import { startHighResLoading, startLowResLoading, stopLoading, toggleLoading } from "../../../redux/general/generalActions";
import { pmsEndPoint } from '../../../config/config.js'
import { pyStudioEndPoint } from '../../../config/config.js'
import axios from "../../../common/interceptors/api-client";

export class SpaceCatalogService {
  dispatch = useDispatch()
  fullCatalog: ProductModel[] = []
  displayedCatalog: ProductModel[] = []
  filters: any
  static selectedSpaceId: string
  static selectedProductId: string
  static abortController

  currentProductId = null;

  constructor() {
    this.displayedCatalog = this.fullCatalog
    this.currentProductId = null;
  }

  static updateSelectedProductId(id) {
    SpaceCatalogService.abortController?.abort();
  }

  static updateSelectedSpaceId(id) {
    SpaceCatalogService.abortController?.abort();
  }

  initCatalog() {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products?limit=15&page=1'
    };

    axios.request(config)
    .then((response) => {
      let products = response.data.data;
      this.dispatch(setProducts(products));
      this.fullCatalog = products;
    })
    .catch((error) => {
      console.error("Error fetching products:", error);
    });
  }

  async getProductBySKU(productSKU) {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products'
    };

    const response = await axios.request(config)
    return response.data.data.find(product => product.sku === productSKU)
  }

  getProductsFilters(products) {
    const mergedSpecifics = products.reduce((acc, item) => {
      Object.keys(item.specifics ?? {}).forEach(key => {
        //@ts-ignore
        acc[key] = [...new Set([...(acc[key] || []), ...item.specifics[key]])];
      });
      return acc;
    }, {});
    return mergedSpecifics
  }

  filterProductsByName(name) {
    return this.displayedCatalog.filter(product => product.name.includes(name))
  }

  showBookmarksOnly(bookmarks) {
    this.dispatch(setDisplayedProducts(bookmarks))
  }


  async processProduct(visualizeData, space) {
    // Cancel any ongoing requests before proceeding
    if (SpaceCatalogService.abortController) {
      console.log('Aborting previous request...');
      SpaceCatalogService.abortController.abort();
    }

    // Create a new AbortController for the current request
    SpaceCatalogService.abortController = new AbortController();
    const signal = SpaceCatalogService.abortController.signal;

    // Set currentProductId to the new product being processed
    const currentProductId = space.id;
    this.currentProductId = currentProductId;

    try {
      const data = JSON.stringify(visualizeData);
      const fetchImage = async (quality = '') => {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${pyStudioEndPoint}/display/${space.id}${quality}`,
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
          data: data,
          signal: signal,
        };
        //@ts-ignore
        const response = await axios.request(config);
        return URL.createObjectURL(response.data);
      };

      this.dispatch(startLowResLoading());

      // Fetch the low-quality image first
      try {
        const imageUrlNoHQ = await fetchImage();
        // Only update state if the current product ID matches
        if (this.currentProductId === currentProductId) {
          this.dispatch(setSelectedProductResult(imageUrlNoHQ));
          this.dispatch(stopLoading());
          this.dispatch(startHighResLoading());
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Low-quality request canceled due to space change:', error.message);
          return; // Exit early if the request was canceled
        } else {
          console.error('Error fetching low-quality image:', error);
          this.dispatch(stopLoading());
          return; // Exit early if an error occurred
        }
      }

      // Add a small delay before fetching the high-quality image
      await new Promise((resolve) => setTimeout(resolve, 200));

      // Fetch the high-quality image
      try {
        const imageUrlHQ = await fetchImage('?img_quality=high');
        // Only update state if the current product ID matches
        if (this.currentProductId === currentProductId) {
          this.dispatch(setSelectedProductResult(imageUrlHQ));
          this.dispatch(stopLoading());
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('High-quality request canceled due to space change:', error.message);
        } else {
          console.error('Error fetching high-quality image:', error);
          this.dispatch(stopLoading());
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled due to space change:', error.message);
      } else {
        console.error('Error processing product:', error);
        this.dispatch(stopLoading());
      }
    }
  }

  async processRug(spaceId) {
    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    return await axios.request(config)
  }

  async processSpaceForRugs(spaceId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }


  async getProductMetadata(productId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pmsEndPoint}/products`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${productId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }

  async getShareId(space, visualizeData) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/sharing',
      data: {
        space,
        visualizeData
      }
    };

    const response = await axios.request(config)
    return response.data
  }
}
