import React, { useState, useEffect } from 'react';
import axios from "../../../../../common/interceptors/api-client";
import { pmsEndPoint } from '../../../../../config/config';
import { useDispatch } from 'react-redux';
import { setProducts } from '../../../../../redux/product/productActions';
import { useSelector } from 'react-redux';
import { FullState } from '../../../../../redux/rootReducer';

const ScrollToBottom = ({ containerRef }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const dispatch = useDispatch()
  const { products, nextPage } = useSelector((state: FullState) => state.productsCatalog)

  const fetchData = async () => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: pmsEndPoint + '/products?limit=15&page=' + nextPage
      };
  
      if(nextPage !== -1)
        axios.request(config)
        .then((response) => {
          let pageProducts = response.data.data;
          const newNextPage = Number.parseInt(response.data.nextPage)
            dispatch(setProducts([...products, ...pageProducts], newNextPage));
        })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const checkScrollPosition = () => {
    const container = containerRef.current;
    if (container) {
      const isBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
      setIsAtBottom(isBottom);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', checkScrollPosition);

    // Cleanup the event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      fetchData();
    }
  }, [isAtBottom]); 

  return <></>;
};

export default ScrollToBottom;
