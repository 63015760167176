import * as _ from 'lodash'
import { useDispatch } from "react-redux";
import { visualizationEndPoint } from '../../../config/config.js'

import axios from "../../../common/interceptors/api-client";
import { toggleLoading } from '../../../redux/general/generalActions';

export class ARVisualizerService {
  dispatch = useDispatch()

  async getARModel(productId) {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: visualizationEndPoint + '/products/ar/' + productId
    };
    const response = await axios.request(config)
    return response.data.path
  }

}